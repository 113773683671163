import React, { useState } from "react";
// import DailyGraph from "../../../../../components/DailyGraph/DailyGraph";
import DailyGraph from "../../../../HomePage/components/DailyGraph";
import { images } from "../../../../../constants";
import { useTheme } from "@mui/material";
import WorkHoursEditor from "./WorkHoursEditor";

import filterAndSortDailyLogs from "../../../../../utils";
import { parse } from "date-fns";

const isTodayOrNull = (date) => {
  if (!date) {
    return true;
  }
  const d = new Date();
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  const today = `${year}-${month}-${day}`;
  return date === today;
};

const WorkHoursTrackerRow = ({
  employed,
  status,
  date,
  employeePerson,
  activeButton,
}) => {
  const [openHoursEditor, setOpenHoursEditor] = useState(false);
  const list = [];
  const theme = useTheme();
  const backgroundAlt = theme.palette.background.alt;
  const neutralMedium = theme.palette.neutral.medium;

  const filteredLogs = filterAndSortDailyLogs(employed.logs, list);

  const parseTime = (time) => {
    const hours = time / 3600;
    const minutes = (time % 3600) / 60;
    return `${hours.toFixed(0).padStart(2, "0")}:${minutes
      .toFixed(0)
      .padStart(2, "0")}`;
  };

  return (
    <>
      <div
        className={`flex-direction-row align-center align-stretch pointer ${
          status === "unregistered" ? "unregistered" : ""
        } workHours-tracker-row`}
        style={{ backgroundColor: backgroundAlt }}
        onClick={() =>
          activeButton === "day" && setOpenHoursEditor(!openHoursEditor)
        }
      >
        <div className="flex-direction-row align-center gap-12">
          <div className="flex-direction-row align-center justify-center workHours-tracker-row-picture">
            {employeePerson ? (
              <p className="Grotesk-500 font-14 workHours-tracker-row-name">
                {/* {employed.date} */}
              </p>
            ) : (
              <div
                style={{ width: "24px", height: "24px", borderRadius: "100%" }}
              >
                <img
                  src={employed.photo ? employed.photo : images.avatar}
                  alt="slika"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                  }}
                />
              </div>
            )}
          </div>
          <p
            className="Grotesk-500 font-14 workHours-tracker-row-name"
            style={{ lineHeight: "18px", height: "18px", color: neutralMedium }}
          >
            {employed.name + " " + employed.surname}
          </p>
        </div>
        <DailyGraph
          list={filteredLogs}
          status={status}
          small={true}
          today={isTodayOrNull(date)}
        />

        <div className="flex-direction-row align-center gap-20 workHours-tracker-row-hours">
          <div className="flex-direction-column justify-center  gap-5 align-stretch">
            <p
              className="Grotesk-500 font-12"
              style={{
                lineHeight: "16px",
                maxWidth: "30px",
                whiteSpace: "wrap",
                overflow: "visible",
                color: neutralMedium,
              }}
            >
              {status === "unregistered"
                ? "Poslednja prijava"
                : status === "checkOut"
                ? "Prijava"
                : status === "holiday"
                ? "Pocetak"
                : "Dnevni"}
            </p>
            <p
              className="Grotesk-400 font-12"
              style={{ lineHeight: "16px", color: neutralMedium }}
            >
              {status === "unregistered"
                ? employed.start
                : status === "checkOut"
                ? employed.checkIn
                : status === "holiday"
                ? employed.start
                : "-" + parseTime(employed?.todayTime)}
            </p>
            {status === "unregistered" && (
              <p
                className="Grotesk-400 font-12"
                style={{ lineHeight: "16px", color: neutralMedium }}
              >
                {"-" + parseTime(employed?.todayTime)}
              </p>
            )}
          </div>
          <div className="flex-direction-column justify-center  gap-5 align-stretch">
            <p
              className="Grotesk-500 font-12"
              style={{
                lineHeight: "16px",
                maxWidth: "30px",
                whiteSpace: "wrap",
                overflow: "visible",
                color: neutralMedium,
              }}
            >
              {status === "unregistered"
                ? "Poslednja odjava"
                : status === "checkOut"
                ? "Odjava"
                : status === "holiday"
                ? "Kraj"
                : "Tekući"}
            </p>
            <p
              className="Grotesk-400 font-12"
              style={{ lineHeight: "16px", color: neutralMedium }}
            >
              {status === "unregistered"
                ? employed.end
                : status === "checkOut"
                ? employed.checkOut
                : status === "holiday"
                ? employed.end
                : "+" + parseTime(employed?.runningTime)}
            </p>
            {status === "unregistered" && (
              <p
                className="Grotesk-400 font-12"
                style={{ lineHeight: "16px", color: neutralMedium }}
              >
                {"+" + parseTime(employed?.runningTime)}
              </p>
            )}
          </div>
        </div>
      </div>

      {openHoursEditor && (
        <WorkHoursEditor list={list} employeeId={employed.id} date={date} />
      )}
    </>
  );
};
export default WorkHoursTrackerRow;
